import {
  amber, indigo, red, common,
} from '@mui/material/colors';

export default (server) => {
  const colors = {
    white: common.white,
    background: indigo[500],
    primary: server?.attributes?.colorPrimary || indigo[800],
    secondary: server?.attributes?.colorSecondary || indigo[800],
    positive: indigo[500],
    medium: amber[700],
    negative: red[500],
    neutral: indigo[500],
    geometry: '#3bb2d0',
  };

  return {
    background: {
      default: colors.background,
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.white,
    },
    colors,
  };
};
