import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Toolbar, useMediaQuery, IconButton, OutlinedInput, InputAdornment, Popover, FormControl, FormGroup, FormControlLabel, Checkbox, Badge, ListItemButton, ListItemText, Tooltip,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import MapIcon from '@mui/icons-material/Map';
import ViewListIcon from '@mui/icons-material/ViewList';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useDeviceReadonly } from '../common/util/permissions';
import DeviceRow from './DeviceRow';
import LogoImage from '../login/LogoImage';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    gap: theme.spacing(1),
    borderRadius: '10%',
    border: 1,
  },
  filterPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    width: theme.dimensions.drawerWidthTablet,
  },
}));

const MainToolbar = ({
  filteredDevices,
  devicesOpen,
  setDevicesOpen,
  keyword,
  setKeyword,
  filter,
  setFilter,
  filterSort,
  setFilterSort,
  filterMap,
  setFilterMap,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const t = useTranslation();
  const deviceReadonly = useDeviceReadonly();

  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);
  const toolbarRef = useRef();
  const inputRef = useRef();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [devicesAnchorEl, setDevicesAnchorEl] = useState(null);
  const deviceStatusCount = (status) => Object.values(devices).filter((d) => d.status === status).length;
  const categories = [...new Set(Object.values(devices).map((device) => device.category))];
  const totalDeviceCount = () => Object.values(devices).length;
  const handleStatusChange = (status, checked) => {
    // Verifica si el estado está seleccionado o no
    if (checked) {
      // Si está seleccionado, agrega el estado al filtro
      setFilter({ ...filter, statuses: [...filter.statuses, status] });
    } else {
      // Si no está seleccionado, elimina el estado del filtro
      setFilter({ ...filter, statuses: filter.statuses.filter((s) => s !== status) });
    }
  };
  return (
    <Toolbar ref={toolbarRef} className={classes.toolbar}>
      {!useMediaQuery(theme.breakpoints.down('lg')) && <LogoImage color={theme.palette.secondary.contrastText} />}
      <IconButton edge="start" onClick={() => setDevicesOpen(!devicesOpen)}>
        {devicesOpen ? <MapIcon /> : <ViewListIcon />}
      </IconButton>
      <OutlinedInput
        ref={inputRef}
        placeholder={t('sharedSearchDevices')}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        onFocus={() => setDevicesAnchorEl(toolbarRef.current)}
        onBlur={() => setDevicesAnchorEl(null)}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton size="small" edge="end" onClick={() => setFilterAnchorEl(inputRef.current)}>
              <Badge color="info" variant="dot" invisible={!filter.statuses.length && !filter.groups.length}>
                <TuneIcon fontSize="small" />
              </Badge>
            </IconButton>
          </InputAdornment>
        )}
        size="small"
        fullWidth
      />
      <Popover
        open={!!devicesAnchorEl && !devicesOpen}
        anchorEl={devicesAnchorEl}
        onClose={() => setDevicesAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: Number(theme.spacing(2).slice(0, -2)),
        }}
        marginThreshold={0}
        PaperProps={{
          style: { width: `calc(${toolbarRef.current?.clientWidth}px - ${theme.spacing(4)})` },
        }}
        elevation={1}
        disableAutoFocus
        disableEnforceFocus
      >
        {filteredDevices.slice(0, 3).map((_, index) => (
          <DeviceRow key={filteredDevices[index].id} data={filteredDevices} index={index} />
        ))}
        {filteredDevices.length > 3 && (
          <ListItemButton alignItems="center" onClick={() => setDevicesOpen(true)}>
            <ListItemText
              primary={t('notificationAlways')}
              style={{ textAlign: 'center' }}
            />
          </ListItemButton>
        )}
      </Popover>
      <Popover
        open={!!filterAnchorEl}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.filterPanel}>
          <FormControl
            label
          >
            {`${t('deviceTitle')} (${totalDeviceCount()})`}
          </FormControl>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={filter.statuses.includes('online')} onChange={(e) => handleStatusChange('online', e.target.checked)} />}
                label={`${t('deviceStatusOnline')} (${deviceStatusCount('online')})`}
              />
              <FormControlLabel
                control={<Checkbox checked={filter.statuses.includes('offline')} onChange={(e) => handleStatusChange('offline', e.target.checked)} />}
                label={`${t('deviceStatusOffline')} (${deviceStatusCount('offline')})`}
              />
              <FormControlLabel
                control={<Checkbox checked={filter.statuses.includes('unknown')} onChange={(e) => handleStatusChange('unknown', e.target.checked)} />}
                label={`${t('deviceStatusUnknown')} (${deviceStatusCount('unknown')})`}
              />
            </FormGroup>
          </FormControl>
          <FormControl
            label
          >
            {t('deviceCategory')}
          </FormControl>
          <FormControl>
            <FormGroup>
              {categories.map((category) => (
                <FormControlLabel
                  key={category}
                  control={(
                    <Checkbox
                      checked={filter.categories.includes(category)}
                      onChange={() => {
                        if (filter.categories.includes(category)) {
                          setFilter((prevFilter) => ({
                            ...prevFilter,
                            categories: prevFilter.categories.filter((c) => c !== category),
                          }));
                        } else {
                          setFilter((prevFilter) => ({
                            ...prevFilter,
                            categories: [...prevFilter.categories, category],
                          }));
                        }
                      }}
                    />
                  )}
                  label={category}
                />
              ))}
            </FormGroup>
          </FormControl>
          <FormControl
            label
          >
            {t('settingsGroups')}
          </FormControl>
          <FormControl>
            <FormGroup>
              {Object.values(groups)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((group) => (
                  <FormControlLabel
                    key={group.id}
                    control={(
                      <Checkbox
                        checked={filter.groups.includes(group.id)}
                        onChange={(e) => {
                          const { checked } = e.target;
                          if (checked) {
                            setFilter((prevFilter) => ({
                              ...prevFilter,
                              groups: [...prevFilter.groups, group.id],
                            }));
                          } else {
                            setFilter((prevFilter) => ({
                              ...prevFilter,
                              groups: prevFilter.groups.filter((id) => id !== group.id),
                            }));
                          }
                        }}
                      />
                )}
                    label={group.name}
                  />
                ))}
            </FormGroup>
          </FormControl>
          <FormControl
            label
          >
            {t('sharedSortBy')}
          </FormControl>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={filterSort === ''}
                    onChange={(e) => setFilterSort(e.target.checked ? '' : 'name')}
                  />
              )}
                label={t('sharedName')}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={filterSort === 'lastUpdate'}
                    onChange={(e) => setFilterSort(e.target.checked ? 'lastUpdate' : '')}
                  />
              )}
                label={t('deviceLastUpdate')}
              />
            </FormGroup>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={filterMap} onChange={(e) => setFilterMap(e.target.checked)} />}
              label={t('sharedFilterMap')}
            />
          </FormGroup>
        </div>
      </Popover>
      <IconButton edge="end" onClick={() => navigate('/settings/device')} disabled={deviceReadonly}>
        <Tooltip open={!deviceReadonly && Object.keys(devices).length === 0} title={t('deviceRegisterFirst')} arrow>
          <AddIcon />
        </Tooltip>
      </IconButton>
    </Toolbar>
  );
};

export default MainToolbar;
